// frontend-app-*/src/index.scss
@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@edx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

$input-focus-box-shadow: $input-box-shadow; // hack to get upgrade to paragon 4.0.0 to work

@import "~@edx/frontend-component-footer/dist/_footer";

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alert.alert-info .alert-icon {
  color: black;
}

#root {
  // Removing a odd 1.5 scaling on checkboxes.:
  input[type=checkbox] {
    transform: none;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1;
  }

  header {
    flex: 0 0 auto;

    .logo {
      display: block;
      box-sizing: content-box;
      position: relative;
      top: 0.1em;
      height: 1.75rem;
      margin-right: 1rem;
      img {
        display: block;
        height: 100%;
      }
    }
  }

  footer {
    flex: 0;
  }
}

#paragon-portal-root {
  .pgn__modal-layer {
    .pgn__modal-close-container {
      right: 1rem !important;
    }
  }
  .confirm-modal .pgn__modal-body {
    overflow: hidden;
  }
}
