@import "@edx/paragon/scss/core/core";

$horizontal-card-gap: 20px;
$vertical-card-gap: 24px;

.base-card {
  height: 332px;
  width: 270px !important;

  .pgn__card-image-cap {
    height: 104px;
    object: {
      fit: cover;
      position: top center;
    }
  }

  .pgn__card-logo-cap {
    bottom: -1.5rem;
    object: {
      fit: scale-down;
      position: center center;
    }
  }

  .product-card-title {
    font: {
      size: 1.125rem;
    }

    line-height: 24px ;
  }

  .product-card-subtitle {
    font: {
      size: 0.875rem; 
    }

    line-height: 24px;
  }

  .product-badge {
    bottom: 2.75rem;
  }
}

.product-card-container {
  gap: $vertical-card-gap $horizontal-card-gap;
  margin: 0 (-$horizontal-card-gap);
  padding: 1rem $horizontal-card-gap;

  .course-subcontainer {
    gap: $vertical-card-gap $horizontal-card-gap;
  }

  @include media-breakpoint-down(lg) {
    overflow-x: scroll;
  }
}

// Workaround for giving the sub-container a greyish background that stretches to the full width of the browser window
// while being placed within the boundaries of the parent container's dimensions
.recommendations-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-color: $light-200;
  z-index: -1;
}
